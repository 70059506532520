<template>
<div class="kt-portlet">
    <div
        v-if="showPortletHeader"
        class="kt-portlet__head kt-portlet__head--lg"
    >
        <div class="kt-portlet__head-label">
            <span
                v-if="icon"
                class="kt-portlet__head-icon pr-0"
            >
                <SVGIcon
                    hex-color="#0f88ef"
                    class="mr-3"
                    :name="icon"
                />
            </span>
            <h3
                v-if="teacher"
                class="kt-portlet__head-title"
            >
                <span>
                    Teacher Profile
                </span>
                <small>
                    {{ teacher.lastName }}, {{ teacher.firstName }}
                </small>
            </h3>
        </div>

        <div
            v-if="role == 'School Admin' && teacher.schoolEmail !== $_userMixins_schoolEmail"
            class="kt-portlet__head-toolbar"
        >
            <div class="kt-portlet__head-actions">
                <router-link
                    :to="{ name: 'UserEdit', params: { schoolEmail: teacher.schoolEmail } }"
                    class="btn btn-clean btn-sm"
                >
                    Edit User
                </router-link>
            </div>
        </div>
    </div>
    <div class="kt-portlet__body px-5 py-4">
        <div class="kt-widget kt-widget--user-profile-3 row">
            <div
                v-if="teacher"
                class="kt-widget__top"
                :class="courseSections.length > 0 ? 'col-xl-6 col-lg-6 col-md-6' : 'w-100'"
            >
                <div
                    class="kt-widget__media"
                    :class="teacher.awsImageUrl ? '' : 'mr-3'"
                >
                    <user-avatar
                        :avatar-user="teacher"
                        class="image"
                        style="max-width: 110px; width: 110px; height: 110px;"
                    >
                        <div
                            class="kt-widget__pic kt-font-boldest kt-font-light kt-widget__pic--primary kt-font-primary"
                            style="max-width: 110px; width: 110px; height: 110px; font-size: 2rem"
                        >
                            {{ teacher.lastName.substring(0, 2) }}
                        </div>
                    </user-avatar>
                </div>

                <div
                    class="kt-widget__content"
                    :class="teacher.awsImageUrl ? '' : 'pl-0'"
                >
                    <div
                        v-if="!isTeacherPortfolio"
                        class="kt-widget__head mt-3"
                        :class="teacher.hasGoogleAccount ? 'pt-3' : ''"
                    >
                        <router-link
                            class="kt-widget__username"
                            :to="{ name: 'TeacherInfo', params: { schoolEmail: teacher.schoolEmail } }"
                        >
                            {{ teacher.lastName }}, {{ teacher.firstName }}
                        </router-link>
                    </div>
                    <div
                        v-else
                        class="kt-widget__head mt-3"
                        :class="teacher.hasGoogleAccount ? 'pt-3' : ''"
                    >
                        <h5 class="kt-widget__username no-hover">
                            {{ teacher.lastName }}, {{ teacher.firstName }}
                        </h5>
                    </div>

                    <div
                        class="kt-widget__subhead"
                        style="white-space: nowrap; overflow: none"
                    >
                        <span
                            v-if="teacher.jobTitle"
                            class="mr-2"
                        >
                            <i class="flaticon2-calendar-3 mr-1" />
                            {{ teacher.jobTitle }}
                        </span>
                        <span
                            v-if="teacher.schoolEmail"
                            class="mr-2"
                        >
                            <i class="flaticon2-new-email mr-1" />
                            {{ teacher.schoolEmail }}
                        </span>
                        <span
                            v-if="!teacher.hasGoogleAccount"
                            class="kt-font-danger d-none"
                        >
                            <br><br>
                            This teacher has not yet logged in and linked a google account
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-if="courseSections.length > 0"
                class="col-xl-6 col-lg-6 col-md-6"
            >
                <div class="kt-widget1">
                    <div
                        class="kt-widget1__item"
                        :class="{
                            'pull-right': !totals || !canViewTotals,
                        }"
                        style="text-align: center;"
                    >
                        <span
                            v-if="totals && canViewTotals"
                            v-b-tooltip.hover="`${totals.categorizedAssignments} Categorized Assignments, ${totals.uncategorizedAssignments} Uncategorized Assignments`"
                            :title="`${totals.totalAssignments} assignments`"
                            class="kt-widget1__number"
                            :class="{
                                'kt-font-danger': totals.totalAssignments == 0 || totals.categorizedAssignments / totals.totalAssignments < 0.6,
                                'kt-font-warning': totals.categorizedAssignments / totals.totalAssignments < 0.85,
                                'kt-font-success': totals.categorizedAssignments / totals.totalAssignments >= 0.85,
                            }"
                        >
                            {{ totals.totalAssignments }}
                            <span class="text-muted">
                                {{ totals.totalAssignments == 1 ? 'assignment' : 'assignments' }}
                            </span>
                        </span>
                        <span
                            v-if="totals && canViewTotals"
                            v-b-tooltip.hover="`${totals.completedReportCards} report cards graded for ${totals.totalSectionStudents} students`"
                            :title="`${totals.completedReportCards} report cards completed`"
                            class="kt-widget1__number"
                            :class="{
                                'kt-font-danger': (totals.completedReportCards == 0 && totals.totalSectionStudents > 0) || (totals.totalSectionStudents > 0 && totals.completedReportCards / totals.totalSectionStudents < 0.6),
                                'kt-font-warning': totals.totalSectionStudents == 0 || totals.completedReportCards / totals.totalSectionStudents < 0.85,
                                'kt-font-success': totals.totalSectionStudents > 0 && totals.completedReportCards / totals.totalSectionStudents >= 0.85,
                            }"
                        >
                            {{ totals.completedReportCards }}
                            <span class="text-muted">
                                {{ totals.completedReportCards == 1 ? 'report card grade' : 'report card grades' }}
                            </span>
                        </span>
                        <span
                            v-if="totals && canViewTotals"
                            v-b-tooltip.hover="`${totals.totalSectionStudents} section students across ${courseSections.length} course sections`"
                            :title="`${totals.completedReportCards} Students`"
                            class="kt-widget1__number"
                            :class="{
                                'kt-font-warning': totals.totalSectionStudents == 0,
                                'kt-font-success': totals.totalSectionStudents > 0,
                            }"
                        >
                            {{ totals.totalSectionStudents }}
                            <span class="text-muted">
                                {{ totals.totalSectionStudents == 1 ? 'student' : 'students' }}
                            </span>
                        </span>
                        <span
                            :title="`${courseSections.length} courses`"
                            class="kt-widget1__number"
                            :class="{
                                'kt-font-brand': courseSections.length == linkedCourseSections.length,
                                'kt-font-success': courseSections.length !== linkedCourseSections.length,
                            }"
                        >
                            {{ courseSections.length }}
                            <span class="text-muted">
                                {{ courseSections.length == 1 ? 'course' : 'courses' }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import UserAvatar from './UserAvatar.vue';
import Vue from 'vue';

import Types from '../store/Types';
import userMixins from '../store/mixins/userMixins';

export default Vue.extend({
    name: 'TeacherHeaderPortlet',
    components: {
        UserAvatar,
    },
    mixins: [
        userMixins,
    ],
    props: {
        showPortletHeader: {
            type: Boolean,
            required: false,
            default: false,
        },
        teacher: {
            type: Object,
            required: false,
            default: null,
        },
        teacherCourses: {
            type: Array,
            required: false,
            default: null,
        },
        schoolTermMarkingPeriodId: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            role: (state) => state.user.school.role,
            deviceType: (state) => state.deviceType,
            schoolTermId: (state) => state.user.school.schoolTermId,
            externalDistrictId: (state) => state.user.school.externalDistrictId,
        }),
        ...mapGetters({
            isNycGoogleDomainUser: Types.getters.IS_NYC_GOOGLE_DOMAIN_USER,
        }),
        courseSections() {
            const { teacher, isTeacherList, teacherCourses } = this;
            if (!teacher) return [];
            return isTeacherList ? teacher.courseSections || [] : teacherCourses || [];
        },
        linkedCourseSections() {
            return this.courseSections.filter((c) => c.googleCourseId);
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        totals() {
            const { teacher } = this;
            const schoolTermMarkingPeriodId = this.$store.state.settings.schoolTermMarkingPeriodId || this.schoolTermMarkingPeriodId;
            if (!schoolTermMarkingPeriodId || !teacher || !teacher.totals) return null;
            return teacher.totals.find((t) => t.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        isTeacherPortfolio() {
            return this.$route.name == 'TeacherPortfolio';
        },
        canViewTotals() {
            const { user, teacher } = this;
            const { userPermissions, school } = user;
            const { schoolStaffId } = school;
            return userPermissions.accessAllTeacherPortfolios || teacher.schoolStaffId == schoolStaffId;
        },
        isTeacherList() {
            return this.$route.name === 'Teachers';
        },
    },
    methods: {
        getCourseColorIndex(course) {
            let str = course.extCourseSectionId;
            let charForColor = str.charCodeAt(0); // first char from course
            if (course.extCourseSectionId.indexOf('-') > -1) {
                // eslint-disable-next-line prefer-destructuring
                str = course.extCourseSectionId.split('-')[0];
                charForColor = str.substr(-1).charCodeAt(0); // last char from course, if nyc
            }

            return Math.floor(charForColor % 10); // 0-9 as return val
        },
        selectTeacher(section) {
            if (section == 'permissions') {
                this.$router.push({ name: 'UserEdit', params: { userId: this.teacher.userId } });
                return;
            }
            this.$router.push({ name: 'TeacherInfo', params: { schoolEmail: this.teacher.schoolEmail } });
        },
        percentage(num, den) {
            if (num <= 0 || den <= 0) return 0;
            const percentage = ((num / den) * 100);
            const roundToDecimals = 1;
            // @ts-ignore
            return Number(`${Math.round(`${percentage}e${roundToDecimals}`)}e-${roundToDecimals}`);
        },
    },
});
</script>

<style scoped>
a.disabled-account {
    font-size: 1.3rem;
}

div.kt-widget1__item span.text-muted {
    font-size: 0.9rem;
    font-weight: 400;
    padding-top: 4px;
    display: block;
}

.kt-widget1__number {
    cursor: pointer;
}

.kt-grid-nav__desc {
    text-overflow: ellipsis;
    overflow: hidden;
}

.kt-portlet__body,
.kt-widget__top {
    overflow: hidden;
}

.no-hover:hover {
    color: #48465b !important;
}
</style>
