var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-portlet" }, [
    _vm.showPortletHeader
      ? _c("div", { staticClass: "kt-portlet__head kt-portlet__head--lg" }, [
          _c("div", { staticClass: "kt-portlet__head-label" }, [
            _vm.icon
              ? _c(
                  "span",
                  { staticClass: "kt-portlet__head-icon pr-0" },
                  [
                    _c("SVGIcon", {
                      staticClass: "mr-3",
                      attrs: { "hex-color": "#0f88ef", name: _vm.icon },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.teacher
              ? _c("h3", { staticClass: "kt-portlet__head-title" }, [
                  _c("span", [_vm._v(" Teacher Profile ")]),
                  _c("small", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.teacher.lastName) +
                        ", " +
                        _vm._s(_vm.teacher.firstName) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm.role == "School Admin" &&
          _vm.teacher.schoolEmail !== _vm.$_userMixins_schoolEmail
            ? _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                _c(
                  "div",
                  { staticClass: "kt-portlet__head-actions" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-clean btn-sm",
                        attrs: {
                          to: {
                            name: "UserEdit",
                            params: { schoolEmail: _vm.teacher.schoolEmail },
                          },
                        },
                      },
                      [_vm._v(" Edit User ")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "kt-portlet__body px-5 py-4" }, [
      _c("div", { staticClass: "kt-widget kt-widget--user-profile-3 row" }, [
        _vm.teacher
          ? _c(
              "div",
              {
                staticClass: "kt-widget__top",
                class:
                  _vm.courseSections.length > 0
                    ? "col-xl-6 col-lg-6 col-md-6"
                    : "w-100",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "kt-widget__media",
                    class: _vm.teacher.awsImageUrl ? "" : "mr-3",
                  },
                  [
                    _c(
                      "user-avatar",
                      {
                        staticClass: "image",
                        staticStyle: {
                          "max-width": "110px",
                          width: "110px",
                          height: "110px",
                        },
                        attrs: { "avatar-user": _vm.teacher },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "kt-widget__pic kt-font-boldest kt-font-light kt-widget__pic--primary kt-font-primary",
                            staticStyle: {
                              "max-width": "110px",
                              width: "110px",
                              height: "110px",
                              "font-size": "2rem",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.teacher.lastName.substring(0, 2)) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "kt-widget__content",
                    class: _vm.teacher.awsImageUrl ? "" : "pl-0",
                  },
                  [
                    !_vm.isTeacherPortfolio
                      ? _c(
                          "div",
                          {
                            staticClass: "kt-widget__head mt-3",
                            class: _vm.teacher.hasGoogleAccount ? "pt-3" : "",
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "kt-widget__username",
                                attrs: {
                                  to: {
                                    name: "TeacherInfo",
                                    params: {
                                      schoolEmail: _vm.teacher.schoolEmail,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.teacher.lastName) +
                                    ", " +
                                    _vm._s(_vm.teacher.firstName) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "kt-widget__head mt-3",
                            class: _vm.teacher.hasGoogleAccount ? "pt-3" : "",
                          },
                          [
                            _c(
                              "h5",
                              { staticClass: "kt-widget__username no-hover" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.teacher.lastName) +
                                    ", " +
                                    _vm._s(_vm.teacher.firstName) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                    _c(
                      "div",
                      {
                        staticClass: "kt-widget__subhead",
                        staticStyle: {
                          "white-space": "nowrap",
                          overflow: "none",
                        },
                      },
                      [
                        _vm.teacher.jobTitle
                          ? _c("span", { staticClass: "mr-2" }, [
                              _c("i", {
                                staticClass: "flaticon2-calendar-3 mr-1",
                              }),
                              _vm._v(" " + _vm._s(_vm.teacher.jobTitle) + " "),
                            ])
                          : _vm._e(),
                        _vm.teacher.schoolEmail
                          ? _c("span", { staticClass: "mr-2" }, [
                              _c("i", {
                                staticClass: "flaticon2-new-email mr-1",
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.teacher.schoolEmail) + " "
                              ),
                            ])
                          : _vm._e(),
                        !_vm.teacher.hasGoogleAccount
                          ? _c(
                              "span",
                              { staticClass: "kt-font-danger d-none" },
                              [
                                _c("br"),
                                _c("br"),
                                _vm._v(
                                  " This teacher has not yet logged in and linked a google account "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm.courseSections.length > 0
          ? _c("div", { staticClass: "col-xl-6 col-lg-6 col-md-6" }, [
              _c("div", { staticClass: "kt-widget1" }, [
                _c(
                  "div",
                  {
                    staticClass: "kt-widget1__item",
                    class: {
                      "pull-right": !_vm.totals || !_vm.canViewTotals,
                    },
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _vm.totals && _vm.canViewTotals
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                value: `${_vm.totals.categorizedAssignments} Categorized Assignments, ${_vm.totals.uncategorizedAssignments} Uncategorized Assignments`,
                                expression:
                                  "`${totals.categorizedAssignments} Categorized Assignments, ${totals.uncategorizedAssignments} Uncategorized Assignments`",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "kt-widget1__number",
                            class: {
                              "kt-font-danger":
                                _vm.totals.totalAssignments == 0 ||
                                _vm.totals.categorizedAssignments /
                                  _vm.totals.totalAssignments <
                                  0.6,
                              "kt-font-warning":
                                _vm.totals.categorizedAssignments /
                                  _vm.totals.totalAssignments <
                                0.85,
                              "kt-font-success":
                                _vm.totals.categorizedAssignments /
                                  _vm.totals.totalAssignments >=
                                0.85,
                            },
                            attrs: {
                              title: `${_vm.totals.totalAssignments} assignments`,
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.totals.totalAssignments) + " "
                            ),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.totals.totalAssignments == 1
                                      ? "assignment"
                                      : "assignments"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.totals && _vm.canViewTotals
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                value: `${_vm.totals.completedReportCards} report cards graded for ${_vm.totals.totalSectionStudents} students`,
                                expression:
                                  "`${totals.completedReportCards} report cards graded for ${totals.totalSectionStudents} students`",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "kt-widget1__number",
                            class: {
                              "kt-font-danger":
                                (_vm.totals.completedReportCards == 0 &&
                                  _vm.totals.totalSectionStudents > 0) ||
                                (_vm.totals.totalSectionStudents > 0 &&
                                  _vm.totals.completedReportCards /
                                    _vm.totals.totalSectionStudents <
                                    0.6),
                              "kt-font-warning":
                                _vm.totals.totalSectionStudents == 0 ||
                                _vm.totals.completedReportCards /
                                  _vm.totals.totalSectionStudents <
                                  0.85,
                              "kt-font-success":
                                _vm.totals.totalSectionStudents > 0 &&
                                _vm.totals.completedReportCards /
                                  _vm.totals.totalSectionStudents >=
                                  0.85,
                            },
                            attrs: {
                              title: `${_vm.totals.completedReportCards} report cards completed`,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.totals.completedReportCards) +
                                " "
                            ),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.totals.completedReportCards == 1
                                      ? "report card grade"
                                      : "report card grades"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.totals && _vm.canViewTotals
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                value: `${_vm.totals.totalSectionStudents} section students across ${_vm.courseSections.length} course sections`,
                                expression:
                                  "`${totals.totalSectionStudents} section students across ${courseSections.length} course sections`",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "kt-widget1__number",
                            class: {
                              "kt-font-warning":
                                _vm.totals.totalSectionStudents == 0,
                              "kt-font-success":
                                _vm.totals.totalSectionStudents > 0,
                            },
                            attrs: {
                              title: `${_vm.totals.completedReportCards} Students`,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.totals.totalSectionStudents) +
                                " "
                            ),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.totals.totalSectionStudents == 1
                                      ? "student"
                                      : "students"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass: "kt-widget1__number",
                        class: {
                          "kt-font-brand":
                            _vm.courseSections.length ==
                            _vm.linkedCourseSections.length,
                          "kt-font-success":
                            _vm.courseSections.length !==
                            _vm.linkedCourseSections.length,
                        },
                        attrs: {
                          title: `${_vm.courseSections.length} courses`,
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.courseSections.length) + " "),
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.courseSections.length == 1
                                  ? "course"
                                  : "courses"
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }